<template>
  <v-card rounded="10">
    <v-card
      elevation="3"
      flat
      rounded="0"
    >
      <v-card-text class="container-general-title">
        <div />

        <div class="container-title">
          <span>Configurações avançadas</span>
        </div>

        <v-icon
          class=""
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-text>

      <v-card-text>
        <separator label="Configuração de usuário" />
      </v-card-text>

      <v-card-text class="container-inputs-details">
        <v-text-field
          v-model="login"
          label="Login"
          outlined
          dense
        ></v-text-field>

        <v-text-field
          v-model="password"
          outlined
          dense
          :type="isPasswordVisible ? 'text' : 'password'"
          label="Senha"
          placeholder="Senha"
          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          class="mb-2"
          data-test="password-input"
          autocomplete="current-password"
          @click:append="isPasswordVisible = !isPasswordVisible"
        />
      </v-card-text>

      <v-card-text class="infos-title">
        <v-icon
          class=""
          @click="closeModal()"
        >
          {{ icons.mdiFileTreeOutline }}
        </v-icon>

        <span>Permissões do usuário</span>
      </v-card-text>

      <v-card-text class="infos-models">
        <v-autocomplete
          v-model="selectedModel"
          :items="listModels"
          :rules="[rules.required]"
          label="Módulos"
          item-text="system"
          item-value="id"
          dense
          outlined
          class="mr-4"
        ></v-autocomplete>

        <v-btn
          color="purple"
          outlined
          class=""
          @click="handleAddModelToList"
        >
          <v-icon class="">
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <!-- Lista de itens -->
      <v-card-text
        v-for="(item, index) in selectedModels"
        :key="item.id"
      >
        <div class="container-item">
          <span>{{ item.system }}</span>

          <div class="container-actions-item">
            <v-icon @click="handleEditModel(item)">
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon
              color="error"
              @click="handleDeleteModel(index)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </div>
      </v-card-text>

      <!-- Modal de Edição -->
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-text>
            <div class="title-modal-edit">
              <span>Editar permissões</span>
              {{ editingModel.system ? editingModel.system : '' }}
            </div>
          </v-card-text>

          <v-card-text>
            <v-container>
              <div
                v-for="(menu, menuIndex) in editingModel.menu"
                :key="menu.id"
              >
                <!-- Nome do Menu -->
                <div>
                  <strong>{{ menu.name }}</strong>
                </div>

                <!-- Ações do Menu -->
                <div
                  class="actions-menus"
                >
                  <v-checkbox
                    v-for="(action, actionIndex) in menu.actions"
                    :key="action.id"
                    v-model="menu.actions[actionIndex].checked"
                    :label="action.name"
                    hide-details
                    class=""
                    @change="updateAction(menuIndex, actionIndex)"
                  ></v-checkbox>
                </div>

                <v-divider></v-divider>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="actions-edit">
            <v-btn
              color="error"
              outlined
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="blue"
              @click="saveEdit"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="infos-models-action">
        <v-btn
          color="error"
          outlined
          class=""
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class=""
        >
          Salvar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCashPlus,
  mdiClose,
  mdiDeleteOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileTreeOutline,
  mdiPencil,
  mdiPlaylistPlus,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

export default {
  components: {
    Separator,

    // NoDataInterface,
  },

  mixins: [messages, formatters],

  data() {
    return {
      selectedModel: null,
      selectedModels: [],
      editingModel: {},
      dialog: false,
      icons: {
        mdiClose,
        mdiCashPlus,
        mdiPlaylistPlus,
        mdiTrashCanOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFileTreeOutline,
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
      },
      isPasswordVisible: false,
      password: '',
      login: '',
      models: '',
      listModels: [
        {
          id: '8bf65ee1-2491-4ec9-97b8-50df999c0d9f',
          system: 'Nexus',
          active: true,
          created_at: '2022-08-26T17:01:16.000000Z',
          updated_at: '2023-01-02T12:04:43.000000Z',
          menu: [],
        },
        {
          id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
          system: 'Integrador',
          active: true,
          created_at: '2022-08-26T18:18:51.000000Z',
          updated_at: '2023-01-02T12:05:14.000000Z',
          menu: [
            {
              id: 'b960e644-7e2b-4779-bb16-eb80727a9904',
              name: 'Dashboard',
              description: 'Dashboard Integrador',
              system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
              created_at: '2025-03-25T13:11:10.000000Z',
              updated_at: '2025-03-25T13:11:10.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '7c547edd-02d9-45fd-b4e5-658e733b6318',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'b960e644-7e2b-4779-bb16-eb80727a9904',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: 'd5541317-0f35-435c-85ea-74c99c4e10cd',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'b960e644-7e2b-4779-bb16-eb80727a9904',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: 'a4866a1a-7ec3-4f22-93de-e78dda557050',
              name: 'Comissões-CNH',
              description: 'Comissões-CNH Integrador',
              system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
              created_at: '2025-03-25T13:11:48.000000Z',
              updated_at: '2025-03-25T13:11:48.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: 'fdb2ba9f-5ea6-4168-af3d-140930ae2e89',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'a4866a1a-7ec3-4f22-93de-e78dda557050',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: 'ba4ec136-aadf-4642-9c4f-fff1149fbf9a',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'a4866a1a-7ec3-4f22-93de-e78dda557050',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: 'dd851a38-6393-4e32-b8a3-c4ed2a4bb841',
              name: 'Entregas',
              description: 'Entregas Integrador',
              system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
              created_at: '2025-03-25T13:12:20.000000Z',
              updated_at: '2025-03-25T13:12:20.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '87ec6c02-8b5a-4d3b-b654-53e95c034a00',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'dd851a38-6393-4e32-b8a3-c4ed2a4bb841',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: 'e4dad42e-f308-422a-b9fc-b9b79b0e4e79',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'dd851a38-6393-4e32-b8a3-c4ed2a4bb841',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: 'ade06a4f-94c7-46b1-9891-7bdaae6bf5be',
              name: 'Inconsistências',
              description: 'Inconsistências Integrador',
              system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
              created_at: '2025-03-25T13:12:53.000000Z',
              updated_at: '2025-03-25T13:12:53.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '02d9fa79-e607-421d-9c36-fa00810b6836',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'ade06a4f-94c7-46b1-9891-7bdaae6bf5be',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: 'b035716e-ec80-4178-867a-8b0b1157dce3',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'ade06a4f-94c7-46b1-9891-7bdaae6bf5be',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: '87d3021c-bc28-4678-b2be-1cdfd8cd617b',
              name: 'BONIFICAÇÕES',
              description: 'BONIFICAÇÕES Integrador',
              system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
              created_at: '2025-03-25T13:13:21.000000Z',
              updated_at: '2025-03-25T13:13:21.000000Z',
              parent_menu_id: null,
              children_menu: [
                {
                  id: '2e8ea5ae-b63d-405d-b414-d2f768e257dc',
                  name: '2° e 5° parcela',
                  description: '2° e 5° parcela Integrador',
                  system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
                  created_at: '2025-03-25T13:14:14.000000Z',
                  updated_at: '2025-03-25T13:14:14.000000Z',
                  parent_menu_id: '87d3021c-bc28-4678-b2be-1cdfd8cd617b',
                  actions: [
                    {
                      id: '88a36397-e38d-4d25-852f-994d0f07bda6',
                      name: 'Ler',
                      description: 'descrição',
                      menu_id: '2e8ea5ae-b63d-405d-b414-d2f768e257dc',
                      created_at: '2025-03-29T13:08:11.000000Z',
                      updated_at: '2025-03-29T13:08:11.000000Z',
                    },
                    {
                      id: '5f39fd04-31b5-4a1e-8aab-d30e84fd385e',
                      name: 'Editar',
                      description: 'descrição',
                      menu_id: '2e8ea5ae-b63d-405d-b414-d2f768e257dc',
                      created_at: '2025-03-29T13:08:11.000000Z',
                      updated_at: '2025-03-29T13:08:11.000000Z',
                    },
                  ],
                },
                {
                  id: '12e12c74-f716-44d2-b25c-4db181f46d5a',
                  name: 'Crescimento',
                  description: 'Crescimento Integrador',
                  system_id: 'adfbf313-d395-4bf9-9441-f52180e1b6da',
                  created_at: '2025-03-25T13:14:37.000000Z',
                  updated_at: '2025-03-25T13:14:37.000000Z',
                  parent_menu_id: '87d3021c-bc28-4678-b2be-1cdfd8cd617b',
                  actions: [
                    {
                      id: '730936a0-dc09-4bde-a75a-fc66df3bcdd5',
                      name: 'Ler',
                      description: 'descrição',
                      menu_id: '12e12c74-f716-44d2-b25c-4db181f46d5a',
                      created_at: '2025-03-29T13:08:11.000000Z',
                      updated_at: '2025-03-29T13:08:11.000000Z',
                    },
                    {
                      id: 'c229af81-1165-426c-8973-632fa763f4d9',
                      name: 'Editar',
                      description: 'descrição',
                      menu_id: '12e12c74-f716-44d2-b25c-4db181f46d5a',
                      created_at: '2025-03-29T13:08:11.000000Z',
                      updated_at: '2025-03-29T13:08:11.000000Z',
                    },
                  ],
                },
              ],
              actions: [],
            },
          ],
        },
        {
          id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
          system: 'AdmNexus',
          active: true,
          created_at: '2022-08-26T18:19:06.000000Z',
          updated_at: '2023-01-02T12:05:30.000000Z',
          menu: [
            {
              id: '839c204d-c888-4210-b070-63d3d1ce2527',
              name: 'Dashboard',
              description: 'Dashboard Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:29:26.000000Z',
              updated_at: '2025-03-25T12:29:26.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: 'be17e117-280a-4610-afe6-8b1c8e25dfa8',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: '839c204d-c888-4210-b070-63d3d1ce2527',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '5cca0d0e-573a-431e-abee-21a6a4795acb',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: '839c204d-c888-4210-b070-63d3d1ce2527',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: '1b423c02-be9c-473f-9031-e3170a4efa53',
              name: 'Sistemas',
              description: 'Sistemas Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:30:35.000000Z',
              updated_at: '2025-03-25T12:30:35.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '49935a23-a333-4640-94f8-e3bf1f3641bb',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: '1b423c02-be9c-473f-9031-e3170a4efa53',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: 'cfa9d83e-4060-4b8f-8e9e-764d2665a2e6',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: '1b423c02-be9c-473f-9031-e3170a4efa53',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: 'acf6ebf2-bbe3-40b8-8ee6-2799d0b116f2',
              name: 'Grupos',
              description: 'Grupos Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:30:52.000000Z',
              updated_at: '2025-03-25T12:30:52.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '530c215e-776a-403d-91a1-bee37f399274',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'acf6ebf2-bbe3-40b8-8ee6-2799d0b116f2',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '61197bc2-dab1-42f3-81e3-3849dcbb1da5',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'acf6ebf2-bbe3-40b8-8ee6-2799d0b116f2',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: '0e55c0b3-053c-4cdb-86cf-c4e80a244d95',
              name: 'Regiões',
              description: 'Regiões Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:31:07.000000Z',
              updated_at: '2025-03-25T12:31:07.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: '5100099f-bb24-45c3-9ac4-182371ddfb18',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: '0e55c0b3-053c-4cdb-86cf-c4e80a244d95',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '08af9101-1a70-4565-8992-312b2bb91233',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: '0e55c0b3-053c-4cdb-86cf-c4e80a244d95',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: '84153a39-72eb-4415-8da0-89c4620bc49d',
              name: 'Perfis',
              description: 'Perfis Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:32:13.000000Z',
              updated_at: '2025-03-25T12:32:13.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: 'f8c1ef7b-6d36-4f2b-92f0-4f3e74d6b182',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: '84153a39-72eb-4415-8da0-89c4620bc49d',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '906fa36a-5a0c-481d-a5c5-25b020eeee8e',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: '84153a39-72eb-4415-8da0-89c4620bc49d',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: 'ab990b3d-8b1e-4a88-be1b-38434069d9f0',
              name: 'Ocupação / Função',
              description: 'Ocupação / Função Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:32:51.000000Z',
              updated_at: '2025-03-25T12:32:51.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: 'a95e482d-04aa-4f1c-86b1-9453bf441704',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: 'ab990b3d-8b1e-4a88-be1b-38434069d9f0',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '8f1937b3-862d-41d0-8a37-e9fe185eafe4',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: 'ab990b3d-8b1e-4a88-be1b-38434069d9f0',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
            {
              id: '0c632d00-f981-4bb2-be4e-9a74274c75d9',
              name: 'Setores',
              description: 'Setores Administrador',
              system_id: '1cb6f6bd-f476-4893-b234-2c82cf3700c4',
              created_at: '2025-03-25T12:33:07.000000Z',
              updated_at: '2025-03-25T12:33:07.000000Z',
              parent_menu_id: null,
              children_menu: [],
              actions: [
                {
                  id: 'da133719-43ab-4eba-8b2f-ecf3ccdd3fa6',
                  name: 'Ler',
                  description: 'descrição',
                  menu_id: '0c632d00-f981-4bb2-be4e-9a74274c75d9',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
                {
                  id: '432ee070-ad06-42c7-89dc-d7f83cd768b8',
                  name: 'Editar',
                  description: 'descrição',
                  menu_id: '0c632d00-f981-4bb2-be4e-9a74274c75d9',
                  created_at: '2025-03-29T13:08:11.000000Z',
                  updated_at: '2025-03-29T13:08:11.000000Z',
                },
              ],
            },
          ],
        },
        {
          id: '5a2f368b-a0af-41a9-a626-585bb570d753',
          system: 'AppNexus',
          active: true,
          created_at: '2022-08-26T18:13:06.000000Z',
          updated_at: '2023-01-02T12:05:46.000000Z',
          menu: [],
        },
        {
          id: '7022fe2c-96c6-4ec0-b305-f5607743928c',
          system: 'Sgq',
          active: true,
          created_at: '2023-01-04T13:43:16.000000Z',
          updated_at: '2023-01-04T13:43:16.000000Z',
          menu: [],
        },
        {
          id: 'd29fc0ac-0b90-4ba9-86bb-7689c8897020',
          system: 'Departamento Pessoal',
          active: true,
          created_at: '2022-08-26T18:13:29.000000Z',
          updated_at: '2023-01-02T12:04:58.000000Z',
          menu: [],
        },
        {
          id: 'cfe2cead-b491-4726-8d70-b28f89b5e6b4',
          system: 'Utilitarios',
          active: true,
          created_at: '2024-06-27T15:49:16.000000Z',
          updated_at: '2024-06-27T15:49:16.000000Z',
          menu: [],
        },
        {
          id: 'c4a2bcdc-8de2-4c4a-9762-4e2d878ad7a5',
          system: 'Solicitações',
          active: true,
          created_at: '2025-01-07T12:36:42.000000Z',
          updated_at: '2025-01-07T12:36:42.000000Z',
          menu: [],
        },
        {
          id: 'bb844c91-45ae-46c1-ada8-fe277b102e05',
          system: 'Transporte',
          active: true,
          created_at: '2025-03-18T12:59:00.000000Z',
          updated_at: '2025-03-18T12:59:00.000000Z',
          menu: [],
        },
      ],
      listOfAddedModules: [],
    }
  },

  created() {
    // this.getListModels()
  },
  methods: {
    handleAddModelToList() {
      if (this.selectedModel) {
        const selectedItem = this.listModels.find(item => item.id === this.selectedModel)
        if (selectedItem && !this.selectedModels.some(m => m.id === selectedItem.id)) {
          this.selectedModels.push({ ...selectedItem })
        }
      }
    },

    handleEditModel(item) {
      console.log(item)

      this.editingModel = item

      console.log(this.editingModel)

      this.dialog = true
    },

    saveEdit() {
      const index = this.selectedModels.findIndex(m => m.id === this.editingModel.id)
      if (index !== -1) {
        this.selectedModels.splice(index, 1, { ...this.editingModel })
      }
      this.dialog = false
    },

    handleDeleteModel(index) {
      this.selectedModels.splice(index, 1)
    },

    async getListModels() {
      try {
        const response = await axiosIns.get('/api/v1/records/system/index')

        this.listModels = response.data.data

        console.log(response)
      } catch (error) {
        await this.$swal({
          icon: 'error',
          title: 'ERRO!',
          text: 'Ocorreu um erro ao buscar os módulos',
        })
      }
    },
  },
}
</script>
<style scoped>
.container-general-title{
  display: flex;
  justify-content: space-between;
}
.container-title{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.container-title span {
  font-size: 18px;
  color: white;
}

.container-action-add-center{
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.actions-add-center{
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.container-inputs-details{
  display: flex;
  gap: 15px;

  margin-top: 20px;
}

.infos-title{
  display: flex;
  align-items: center;
  gap: 10px;
}

.infos-models{
display: flex;
align-items: flex-start;
}

.infos-models-action{
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.container-item{
  display: flex;
  justify-content: space-between;
  background: #3B355A;
  border-radius: 5px;
  padding: 25px;
}

.container-actions-item{
  display: flex;
  gap: 10px;
}

.title-modal-edit{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.title-modal-edit span{
  font-size: 18px;
}

.actions-edit{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.actions-menus{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>
